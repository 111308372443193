import React from 'react';
import { toAbsoluteUrl } from '../../_metronic/_helpers';
import Group from '../icons/Group.svg';

function DisabledInput({ id, label, value, type, kycMatch }) {
  return (
    <section
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexBasis: '50%',
        gap: '5px',
        justifyContent: 'space-between',
        marginTop: '1.5rem',
        marginBottom: '1.5rem',
        position: 'relative',
      }}
    >
      <label
        style={{
          fontFamily: 'Poppins',
          fontSize: '13px',
          fontWeight: '500',
          lineHeight: '16px',
          letterSpacing: '-0.05em',
          textAlign: 'left',
        }}
        htmlFor={id}
      >
        {label}
      </label>
      <div
        style={{
          position: 'relative',
        }}
      >
        <input
          type={type}
          disabled
          value={value}
          id={id}
          style={{
            width: '90%',
            height: '45.46662521362305px',
            borderRadius: '3.7888855934143066px',
            background: '#F5F9FD',
            textAlign: 'center',
            border: '0.38px solid #CAE2F6',
            paddingRight: '30px',
          }}
        />
        {kycMatch !== 'false' && (
          <img
            src={`${toAbsoluteUrl(Group)}`}
            alt="Checkmark"
            style={{
              width: '20px',
              height: '20px',
              position: 'absolute',
              top: '50%', // Adjust the vertical alignment as needed
              right: '5rem', // Adjust the distance from the right edge as needed
              transform: 'translateY(-50%)', // Center the image vertically
            }}
          />
        )}
      </div>
    </section>
  );
}

export default DisabledInput;
