import Pagination from '@material-ui/lab/Pagination';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import NumberOfRows from '../../../common/SelectNumberOfRows';
import { formatNumber } from '../../../helpers/helper';
import { getPlanDetails } from '../_redux/planCrud';

const investorTableHeaders = [
  'Investor  ID',
  'User ID',
  'User Name',
  'Email',
  'Telephone',
  'Completed Deposits',
  'Completed Disinvestment',
  'Units',
  'Current balance',
];
const SinglePlan = () => {
  const { id } = useParams();
  const [users, setUsers] = useState(null);
  const [totalRecord, setTotalRecord] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(30);
  const [loading, setLoading] = useState(true);
  const getPlanUsers = async id => {
    const res = await getPlanDetails(id, page, limit);
    if (res.status === 200) {
      setUsers(res.data.items);
      setTotalRecord(res.data.meta.totalItems);
      setTotalPages(res.data.meta.totalPages);
    }
  };
  const handleChangeLimit = async event => {
    const { value: selectedValue } = event.target;
    setPage(1);
    const res = await getPlanDetails(id, page, selectedValue);
    setLimit(selectedValue);
    if (res.status === 200) {
      setTotalRecord(res.data.meta.totalItems);
      setTotalPages(res.data.meta.totalPages);

      setUsers(res.data.items);
    }
  };

  const handleChangePage = async (event, value) => {
    const res = await getPlanDetails(id, value, limit);

    setPage(value);
    if (res.status === 200) {
      setTotalRecord(res.data.meta.totalItems);
      setTotalPages(res.data.meta.totalPages);

      setUsers(res.data.items);
      window.scrollTo({ behavior: 'smooth', top: '0px' });
    }
  };
  useEffect(() => {
    setLoading(true);

    getPlanUsers(id);
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }
  return (
    <>
      <main className="card card-custom card-stretch gutter-b">
        <section
          style={{
            width: '95%',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <div className="card-header">
            <div
              className="mt-10"
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <h1>Fund</h1>
              <p>List of all users invested in fund</p>
            </div>
          </div>
        </section>
        <section className="card-body mt-3 mb-10 pt-0 pb-3">
          <div className="tab-content">
            <div>
              <table className="table table-responsive table-head-custom table-head-bg  table-border table-vertical-center">
                <thead>
                  <tr className="text-left text-uppercase">
                    {investorTableHeaders.map((header, index) => (
                      <th
                        key={index}
                        style={{
                          minWidth: '100px',
                          textAlign: 'center',
                        }}
                        className="pl-7"
                      >
                        <span
                          style={{
                            color: '#3699FF',
                          }}
                        >
                          {header}
                        </span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {users?.map(eachInvestor => (
                    <tr key={eachInvestor.investorId}>
                      <td className="pl-0 py-8 text-center">
                        <span className="d-block font-size-sm">
                          {eachInvestor.investorId}
                        </span>
                      </td>
                      <td className="pl-0 py-8 text-center ">
                        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                          {eachInvestor.userId ? eachInvestor.userId : '---'}
                        </div>
                      </td>
                      <td className="pl-0 py-8 text-center">
                        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                          {eachInvestor.userName
                            ? eachInvestor.userName
                            : '---'}
                        </div>
                      </td>

                      <td className="pl-0 py-8 text-center">
                        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                          {eachInvestor.email ? eachInvestor.email : '---'}
                        </div>
                      </td>
                      <td className="pl-0 py-8 text-center">
                        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                          {eachInvestor.phoneNumber
                            ? eachInvestor.phoneNumber
                            : '---'}
                        </div>
                      </td>

                      <td className="pl-0 py-8 text-center">
                        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                          {eachInvestor.totalCompletedDeposits
                            ? formatNumber(
                                eachInvestor.totalCompletedDeposits.toFixed(2),
                              ) +
                              ' ' +
                              eachInvestor.planCurrency
                            : '---'}
                        </div>
                      </td>

                      <td className="pl-0 py-8 text-center">
                        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                          {eachInvestor.completedDisinvestment
                            ? formatNumber(
                                eachInvestor.completedDisinvestment.toFixed(2),
                              ) +
                              ' ' +
                              eachInvestor.planCurrency
                            : '---'}
                        </div>
                      </td>

                      <td className="pl-0 py-8 text-center">
                        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                          {eachInvestor.units
                            ? formatNumber(eachInvestor.units.toFixed(2))
                            : '---'}
                        </div>
                      </td>

                      <td className="pl-0 py-8 text-center">
                        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                          {eachInvestor.currentBalance
                            ? formatNumber(
                                eachInvestor.currentBalance.toFixed(2),
                              ) +
                              ' ' +
                              eachInvestor.planCurrency
                            : '---'}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
            {totalPages && totalPages > 0 ? (
              <Pagination
                page={page}
                count={totalPages}
                onChange={handleChangePage}
              />
            ) : (
              ''
            )}
            {totalRecord && totalRecord > 10 ? (
              <NumberOfRows
                handleChangeLimit={handleChangeLimit}
                totalRecord={totalRecord}
              />
            ) : (
              ''
            )}
          </div>
        </section>
      </main>
    </>
  );
};

export default SinglePlan;
