import dotenv from 'dotenv';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';
import CommonButton from '../../../common/CommonBtn';
import ErrorCard from '../../../common/ErrorCard';
import FormDisplay from '../../../common/FormDisplay/FormDisplay';
import HighlightedHeader from '../../../common/FormDisplay/HighlightedHeader';
import SelectBox from '../../../common/SelectBox';
import {
  AdminPermissions,
  BusinessTypes,
  dashForUnvailableData,
  IdentificationType,
  InvestorTypes,
  KycStatus,
  kycStatusValues,
  userKycTiers,
  UserLevelOptions1,
} from '../../../helpers/constants';
import BackIcon from '../../../icons/backIcon.svg';
import {
  downloadUsersFile,
  fetchInvestor,
  getImageURL,
  updateBusinessChamaInvestorInfo,
  updateInvestorInfo,
} from '../../Users-kyc/_redux/KycCrud';
import { getInvestorStatement, getKycRejectionReasons } from '../_redux';
import { countries } from '../helpers/Helpers';
import ImageContainer from './ImageContainer';
import './index.scss';
import StatementDateModal from './StatementDateModal';
import UsersTable from './UsersTable';
import Adjust from '@adjustcom/adjust-web-sdk';

dotenv.config();

const InvestorProfile = props => {
  const history = useHistory();
  const location = useLocation();

  const { investorId } = props.match.params;
  const [loading, setLoading] = useState(true);
  const [statementModalOpen, setStatementModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [investorsData, setInvestorsData] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [downloadingImage, setDownloadingImage] = useState(false);
  const [downloadingTaxPinImage, setDownloadingTaxPinImage] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [imageURL, setImageURL] = useState('');
  const [
    certificateOfRegistrationURL,
    setcertificateOfRegistrationURL,
  ] = useState('');
  const [
    certificateOfIncorparationURL,
    setcertificateOfIncorparationURL,
  ] = useState('');

  const [allKycRejectionReasons, setAllKycRejectionReasons] = useState([]);

  const [CR12CertificateURL, setCR12CertificateURL] = useState('');
  const [businessChamaDirectorIdURL, setbusinessChamaDirectorIdURL] = useState(
    '',
  );
  const [letterOfAuthorizationURL, setletterOfAuthorizationURL] = useState('');

  const [faceURL, setFaceURL] = useState('');
  const [proofOfAddress, setProofOfAddress] = useState('');
  const [taxPinURL, settaxPinURL] = useState('');

  const FILE_SIZE = 5 * 1024 * 1024;

  const [files, setFiles] = useState([]);
  const [error, setError] = useState();
  const [nationalIdIsValid, setNationalIdIsValid] = useState(true);
  const [passportIsValid, setPassportIsValid] = useState(true);
  const [taxPinCertificateIsValid, setTaxPinCertificateIsValid] = useState(
    true,
  );
  const [boardResolutionLetterURL, setBoardResolutionLetter] = useState('');
  const [idType, setIdType] = useState(investorsData?.personalIdentity?.type);
  const [showRejectedReasons, setShowRejectedReasons] = useState(false);
  const [dateValues, setDateValues] = useState({});
  const handleDateChange = event => {
    setDateValues({ ...dateValues, [event.target.name]: event.target.value });
  };

  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  const handleStatementModalClose = dateValues => {
    const res = getInvestorStatement(dateValues, id);
    return res.data;
  };
  const getImageFormat = arrayBuffer => {
    const arr = new Uint8Array(arrayBuffer).subarray(0, 4);
    let header = '';

    for (let i = 0; i < arr.length; i++) {
      header += arr[i].toString(16);
    }

    switch (true) {
      case /^89504e47/.test(header):
        return 'image/png';
      case /^ffd8ff/.test(header):
        return 'image/jpeg';
      case /^25504446/.test(header):
        return 'application/pdf';
      default:
        return false;
    }
  };

  const validateFile = (file, name) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = e => {
      const result = e.target.result;
      const valid = getImageFormat(result);
      if (!valid) {
        name === 'nationalId' && setNationalIdIsValid(false);
        name === 'taxPinCertificate' && setTaxPinCertificateIsValid(false);
        name === 'passport' && setPassportIsValid(false);
        setError('You can upload file with png or jpg or pdf extension');
      }
    };
  };

  const handleCardUpload = (e, name) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    if (name === 'nationalId') {
      setNationalIdIsValid(true);
      nationalIdIsValid && setError(null);
    }

    if (name === 'taxPinCertificate') {
      setTaxPinCertificateIsValid(true);
      taxPinCertificateIsValid && setError(null);
    }

    if (name === 'passport') {
      setPassportIsValid(true);
      passportIsValid && setError(null);
    }

    validateFile(file, name);

    const checkExist = files.some(val => val.name === name);

    if (checkExist) {
      const getIndex = files.findIndex(val => val.name === name);
      files[getIndex] = { name: name, file };
      setFiles([...files]);
    } else {
      setFiles([...files, { name: name, file }]);
    }
  };

  const handleAlert = (message, iconType) => {
    Swal.fire({
      icon: iconType,
      position: 'top-end',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
      text: message,
      showConfirmButton: false,
      timer: 1800,
    });
  };

  const openStatementModal = () => {
    setStatementModalOpen(true);
  };

  const handleOpeningFile = async (link, clickedButton) => {
    if (clickedButton === 'taxPinImage') setDownloadingTaxPinImage(true);
    if (clickedButton === 'userIdentification') setDownloadingImage(true);

    downloadUsersFile(link)
      .then(response => {
        const file = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        const fileURL = URL.createObjectURL(file);
        setDownloadingImage(false);
        setDownloadingTaxPinImage(false);
        window.open(fileURL, '_blank');
      })
      .catch(() => {
        setDownloadingImage(false);
        setDownloadingTaxPinImage(false);
        setErrorMessage('Failed to download this document');
      });
  };

  const getInvestor = async () => {
    try {
      setLoading(true);
      const res = await fetchInvestor(investorId);
      if (res.status === 200) {
        const userData = res.data.data;
        setInvestorsData(userData);
        setUserStatus(userData?.status);
        setIdType(userData?.personalIdentity?.type);
        setLoading(false);

        setShowRejectedReasons(!!userData.kycRejectionReason);
        setRejectionReason(userData.kycRejectionReason);
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const fetchKycRejectionReasons = async () => {
    try {
      const res = await getKycRejectionReasons();
      if (res.status === 200) {
        const options = res.data.data.map(item => ({
          id: item.id,
          value: item.id,
          name: item.description,
        }));
        setAllKycRejectionReasons(options);
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
    }
  };

  const updateInvestorData = async () => {
    let formData = new FormData();
    const checkSize = files
      .map(file => file.file.size)
      .every(size => size < FILE_SIZE);

    if (!checkSize) {
      setError('Maximum size of the file should be 2MB.');
      return;
    }
    for (let i = 0; i < files?.length; i++) {
      const { file, name } = files[i];

      if (name === 'passport') {
        formData.delete('front', file);
        formData.delete('idType');
        formData.append('front', file);
        formData.append('idType', IdentificationType.PASSPORT);
      } else if (name === 'nationalId') {
        formData.delete('front', file);
        formData.delete('idType');
        formData.append('front', file);
        formData.append('idType', IdentificationType.NATIONAL_ID);
      } else {
        formData.append(name, file);
      }
    }
    kycStatus && formData.append('kycStatus', kycStatus);
    userLevel && formData.append('userLevel', userLevel);
    kycRejectionReasonId &&
      formData.append('kycRejectionReasonId', parseInt(kycRejectionReasonId));

    try {
      setLoadingData(true);
      let res;
      if (investorType === InvestorTypes.INDIVIDUAL) {
        res = await updateInvestorInfo(investorId, formData);
      } else {
        res = await updateBusinessChamaInvestorInfo(investorId, formData);
      }

      if (res.status === 200) {
        setLoadingData(false);
        setFiles([]);
        if (kycStatus === KycStatus.COMPLETED) {
          Adjust.trackEvent({
            eventToken: `${process.env.REACT_APP_ADJUST_KYC_COMPLETED_EVENT_ID}`,
          });
        }
        handleAlert(res.data.message, 'success');
        await getInvestor();
      }
    } catch (error) {
      setFiles([]);
      setLoadingData(false);
      handleAlert(error.response.data.message, 'error');
    }
  };

  useEffect(() => {
    getInvestor();
    fetchKycRejectionReasons();
  }, []);

  useEffect(() => {
    const getImage = async (fileLink, setLink) => {
      if (fileLink) {
        try {
          const response = await getImageURL(fileLink);
          const contentType = response.headers['content-type'];

          if (contentType.includes('image')) {
            // Handle image files
            const file = new Blob([response.data], { type: contentType });
            const fileURL = URL.createObjectURL(file);
            setLink(fileURL);
          } else if (contentType.includes('application/pdf')) {
            // Handle PDF files
            const pdfFile = new Blob([response.data], { type: contentType });
            const pdfFileURL = URL.createObjectURL(pdfFile);
            setLink(pdfFileURL);
          } else {
            console.error('Unsupported file type');
          }
        } catch (error) {
          console.error(error);
        }
      }
    };
    getImage(investorsData?.personalIdentity?.front, setImageURL);
    getImage(investorsData?.facePhoto, setFaceURL);
    getImage(investorsData?.taxPinCertificate, settaxPinURL);
    getImage(investorsData?.proofOfAddress, setProofOfAddress);
    getImage(
      investorsData?.certificateOfRegistration,
      setcertificateOfRegistrationURL,
    );
    getImage(
      investorsData?.certificateOfIncorporation,
      setcertificateOfIncorparationURL,
    );
    getImage(investorsData.CR12Certificate, setCR12CertificateURL);
    getImage(
      investorsData?.businessChamaDirectorIdentity?.passportOrNationalId,
      setbusinessChamaDirectorIdURL,
    );
    getImage(investorsData?.letterOfAuthorization, setletterOfAuthorizationURL);
    getImage(investorsData?.boardResolutionLetter, setBoardResolutionLetter);
  }, [
    investorsData?.personalIdentity?.front,
    investorsData?.facePhoto,
    investorsData?.taxPinCertificate,
    investorsData?.proofOfAddress,
    investorsData?.certificateOfRegistration,
  ]);

  const [kycStatus, setKycStatus] = useState(investorsData.kycStatus);
  const [userLevel, setUserLevel] = useState(investorsData?.userLevel);
  const [pdfLoading, setPdfLoading] = useState(false);

  const setKycStatusValue = e => {
    setKycStatus(e);
    setShowRejectedReasons(e === KycStatus.REJECTED);
  };

  const {
    createdAt,
    id,
    firstName,
    lastName,
    city,
    country,
    postalAddress,
    postalCode,
    residentialAddress,
    kycRejectionReason,
    kycSubmittedAt,
    phoneNumber,
    nextOfKinContact,
    nextOfKinEmail,
    nextOfKinNames,
    kycExtractionMatch,
    investorType,
  } = investorsData;

  const [userStatus, setUserStatus] = useState(investorsData.status);

  const [kycRejectionReasonId, setKycRejectionReasonId] = useState(null);
  const [rejectionReason, setRejectionReason] = useState(kycRejectionReason);

  const InvestorDetails = [
    {
      title: 'Investor Type ',
      value: investorType,
      label: 'Investor Type',
      id: 'investorType',
      type: 'text',
      kycMatch: kycExtractionMatch,
    },
    {
      title: 'Investor Name',
      value:
        investorType === InvestorTypes.INDIVIDUAL
          ? investorsData?.accountOwner?.firstName +
            ' ' +
            investorsData?.accountOwner?.lastName
          : investorsData.businessChamaName,
      label: 'Investor  Name',
      id: 'investorName',
      type: 'text',
      kycMatch: kycExtractionMatch,
    },
    {
      title: 'Investor Email',
      value:
        investorType === InvestorTypes.INDIVIDUAL
          ? investorsData?.accountOwner?.email
            ? investorsData?.accountOwner?.email
            : dashForUnvailableData
          : investorsData.businessChamaEmail
          ? investorsData.businessChamaEmail
          : dashForUnvailableData,
      label: 'Investor  Email',
      id: 'investorEmail',
      type: 'text',
      kycMatch: kycExtractionMatch,
    },
    {
      title: 'ID Type',
      value:
        investorType === InvestorTypes.INDIVIDUAL
          ? investorsData?.personalIdentity?.type
            ? investorsData?.personalIdentity?.type
            : dashForUnvailableData
          : investorsData.businessChamaDirectorIdentity
              ?.businessChamaAdminIdentityType
          ? investorsData.businessChamaDirectorIdentity
              ?.businessChamaAdminIdentityType
          : dashForUnvailableData,
      label: 'ID Type',
      id: 'IdType',
      type: 'text',
    },
    {
      title: 'ID Number',
      value:
        investorType === InvestorTypes.INDIVIDUAL
          ? investorsData?.personalIdentity?.identificationNumber
            ? investorsData?.personalIdentity?.identificationNumber
            : dashForUnvailableData
          : investorsData?.businessChamaDirectorIdNumber
          ? investorsData?.businessChamaDirectorIdNumber
          : dashForUnvailableData,
      label: 'ID Number',
      id: 'IdNumber',
      type: 'text',
    },

    {
      title: 'Phone Number',
      value: phoneNumber ? phoneNumber : dashForUnvailableData,

      label: 'Phone Number',
      id: 'phoneNumber',
      type: 'phoneNumber',
      kycMatch: kycExtractionMatch,
    },

    {
      title: 'Source of Funds',
      value: investorsData?.sourceOfFunds
        ? investorsData?.sourceOfFunds
        : dashForUnvailableData,
      label: 'Source of Funds',
      id: 'sourceOfFunds',
      type: 'text',
      kycMatch: kycExtractionMatch,
    },
  ];
  const EntityDetails = [
    {
      title: 'Investor Type ',
      value: investorType,
      label: 'Investor Type',
      id: 'investorType',
      type: 'text',
      kycMatch: kycExtractionMatch,
    },
    {
      title: 'Entity Name',
      value: investorsData.businessChamaName,
      label: 'Entity  Name',
      id: 'entityName',
      type: 'text',
      kycMatch: kycExtractionMatch,
    },
    {
      title: 'Entity Email',
      value: investorsData.businessChamaEmail
        ? investorsData.businessChamaEmail
        : dashForUnvailableData,
      label: 'Entity  Email',
      id: 'entityEmail',
      type: 'text',
      kycMatch: kycExtractionMatch,
    },
    {
      title: 'Phone Number',
      value: phoneNumber ? phoneNumber : dashForUnvailableData,

      label: 'Phone Number',
      id: 'phoneNumber',
      type: 'phoneNumber',
      kycMatch: kycExtractionMatch,
    },
    {
      id: 'taxPayerNumber',
      label: 'Tax Payer Number',

      value: investorsData?.taxPayerNumber
        ? investorsData?.taxPayerNumber
        : dashForUnvailableData,
      type: 'text',
    },
    {
      id: 'registrationNumber',
      label: 'Entity Registration Number',
      value: investorsData?.businessChamaRegistrationNumber
        ? investorsData?.businessChamaRegistrationNumber
        : dashForUnvailableData,
      type: 'text',
    },

    {
      title: 'Source of Funds',
      value: investorsData?.sourceOfFunds
        ? investorsData?.sourceOfFunds
        : dashForUnvailableData,
      label: 'Source of Funds',
      id: 'sourceOfFunds',
      type: 'text',
      kycMatch: kycExtractionMatch,
    },
  ];

  const directorDetails = [
    {
      id: 'directorsName',
      label:
        investorType === InvestorTypes.BUSINESS
          ? 'Director Name'
          : "Treasurer's Name",
      value: investorsData?.businessChamaDirectorName
        ? investorsData?.businessChamaDirectorName
        : dashForUnvailableData,
      type: 'text',
    },
    {
      id: 'directorsCountry',
      label:
        investorType === InvestorTypes.BUSINESS
          ? 'Director Country'
          : "Treasurer's Country",
      value: investorsData?.businessChamaDirectorCountry
        ? investorsData?.businessChamaDirectorCountry
        : dashForUnvailableData,
      type: 'text',
    },
    {
      title:
        investorType === InvestorTypes.BUSINESS
          ? "Director's ID Type"
          : "Treasurer's ID Type",
      value: investorsData.businessChamaDirectorIdentity
        ?.businessChamaAdminIdentityType
        ? investorsData.businessChamaDirectorIdentity
            ?.businessChamaAdminIdentityType
        : dashForUnvailableData,
      label:
        investorType === InvestorTypes.BUSINESS
          ? "Director's ID Type"
          : "Treasurer's ID Type",
      id: 'IdType',
      type: 'text',
    },
    {
      title:
        investorType === InvestorTypes.BUSINESS
          ? "Director's ID Number"
          : "Treasurer's ID Number",
      value: investorsData?.businessChamaDirectorIdNumber
        ? investorsData?.businessChamaDirectorIdNumber
        : dashForUnvailableData,
      label:
        investorType === InvestorTypes.BUSINESS
          ? "Director's ID Number"
          : "Treasurer's ID Number",
      id: 'IdNumber',
      type: 'text',
    },
  ];
  const otherChamaImages = [
    {
      id: 1,
      url: certificateOfRegistrationURL,
      originalURL: investorsData?.certificateOfRegistration,
      fileName: 'certificateOfRegistration',
      investorsData,
      files,
      iframeId: 'certificateOfRegistration',
      title: 'Certificate Of Registration',
    },

    {
      id: 2,
      url: taxPinURL,
      originalURL: investorsData?.taxPinCertificate,
      fileName: 'Tax cert',
      investorsData,
      files,
      iframeId: 'taxPinCertificate',
      title: "Treasurer's Tax Pin Cert",
    },
    {
      id: 3,
      url: letterOfAuthorizationURL,
      originalURL: investorsData?.letterOfAuthorization,
      fileName: 'letterOfAuthorization',
      iframeId: 'letterOfAuthorization',
      investorsData,
      files,
      title: 'Letter of authorization',
    },
    {
      id: 4,
      url: businessChamaDirectorIdURL,
      originalURL:
        investorsData?.businessChamaDirectorIdentity?.passportOrNationalId,
      fileName: 'businessChamaDirectorIdentity',
      investorsData,
      files,
      iframeId: 'businessChamaDirectorIdURL',
      title: "Treasurer's Id",
    },
  ];

  const otherIncorpImages = [
    {
      id: 1,
      url: certificateOfRegistrationURL,
      originalURL: investorsData?.certificateOfRegistration,
      fileName: 'certificateOfRegistration',
      investorsData,
      files,
      iframeId: 'certificateOfRegistration',
      title: 'Certificate Of Registration',
    },

    {
      id: 2,
      url: taxPinURL,
      originalURL: investorsData?.taxPinCertificate,
      fileName: 'Tax cert',
      investorsData,
      files,
      iframeId: 'taxPinCertificate',
      title: "Business Director's Tax Pin Cert",
    },
    {
      id: 3,
      url: businessChamaDirectorIdURL,
      originalURL:
        investorsData?.businessChamaDirectorIdentity?.passportOrNationalId,
      fileName: 'businessChamaDirectorIdentity',
      investorsData,
      files,
      iframeId: 'businessChamaDirectorIdURL',
      title: "Business Director's Id",
    },
  ];

  const partnershipImages = [
    {
      id: 1,
      url: certificateOfRegistrationURL,
      originalURL: investorsData?.certificateOfRegistration,
      fileName: 'certificateOfRegistration',
      investorsData,
      files,
      iframeId: 'certificateOfRegistration',
      title: 'Certificate Of Registration',
    },

    {
      id: 2,
      url: CR12CertificateURL,
      originalURL: investorsData?.CR12Certificate,
      fileName: 'CR12Certificate',
      iframeId: 'CR12Certificate',
      investorsData,
      files,
      title: 'CR12 Certificate',
    },
    {
      id: 3,
      url: taxPinURL,
      originalURL: investorsData?.taxPinCertificate,
      fileName: 'Tax cert',
      investorsData,
      files,
      iframeId: 'taxPinCertificate',
      title: "Business Director's Tax Pin Cert",
    },
    {
      id: 4,
      url: boardResolutionLetterURL,
      originalURL: investorsData?.boardResolutionLetter,
      fileName: 'boardResolutionLetter',
      iframeId: 'boardResolutionLetter',
      investorsData,
      files,
      title: 'Board Resolution Letter',
    },
    {
      id: 5,
      url: businessChamaDirectorIdURL,
      originalURL:
        investorsData?.businessChamaDirectorIdentity?.passportOrNationalId,
      fileName: 'businessChamaDirectorIdentity',
      investorsData,
      files,
      iframeId: 'businessChamaDirectorIdURL',
      title: "Business Director's Id",
    },
  ];

  const individualImages = [
    {
      id: 1,
      url: imageURL,
      originalURL: investorsData?.personalIdentity?.front,
      fileName: 'National ID',
      investorsData,
      files,
      iframeId: 'nationalId',
      title: 'National Id',
    },
    {
      id: 2,
      url: faceURL,
      originalURL: investorsData?.personalIdentity?.front,
      fileName: 'Photo',
      investorsData,
      files,
      iframeId: 'facePhoto',
      title: 'Photo',
    },
    {
      id: 3,
      url: taxPinURL,
      originalURL: investorsData?.taxPinCertificate,
      fileName: 'Tax cert',
      investorsData,
      files,
      iframeId: 'taxPinCertificate',
      title: 'Tax pin cert',
    },

    {
      id: 4,
      url: proofOfAddress,
      originalURL: investorsData?.proofOfAddress,
      fileName: 'Address',
      investorsData,
      files,
      iframeId: 'Address',
      title: 'Address',
    },
  ];

  const otherBusinessImages = [
    {
      id: 1,
      url: CR12CertificateURL,
      originalURL: investorsData?.CR12Certificate,
      fileName: 'CR12Certificate',
      iframeId: 'CR12Certificate',
      investorsData,
      files,
      title: 'Upload CR12 certificate',
    },
    {
      id: 2,
      url: boardResolutionLetterURL,
      originalURL: investorsData?.boardResolutionLetter,
      fileName: 'boardResolutionLetter',
      iframeId: 'boardResolutionLetter',
      investorsData,
      files,
      title: 'Upload  board resolution letter',
    },
  ];

  const userInvestorDetails = [
    {
      id: 'country',
      label: 'Country ',
      value: countries[country] ? countries[country] : dashForUnvailableData,
      type: 'text',
    },
    {
      id: 'city',
      label: 'City',
      value: city ? city : dashForUnvailableData,
      type: 'text',
    },
    {
      id: 'postalAddress',
      label: 'Postal Address',
      value: postalAddress ? postalAddress : dashForUnvailableData,
      type: 'text',
    },
    {
      id: 'postalCode',
      label: 'Postal Code',
      value: postalCode ? postalCode : dashForUnvailableData,
      type: 'text',
    },
    {
      id: 'residentialAddress',
      label: 'Residential Address',
      value: residentialAddress ? residentialAddress : dashForUnvailableData,
      type: 'text',
    },
  ];

  const individualFinancialDetails = [
    {
      id: 'taxPayerNumber',
      label: 'Tax Payer Number',

      value: investorsData?.taxPayerNumber
        ? investorsData?.taxPayerNumber
        : dashForUnvailableData,
      type: 'text',
    },
    {
      id: 'registrationNumber',
      label: 'Registration Number',
      value:
        investorType === InvestorTypes.INDIVIDUAL
          ? investorsData?.personalIdentity?.identificationNumber
            ? investorsData?.personalIdentity?.identificationNumber
            : dashForUnvailableData
          : investorsData?.businessChamaRegistrationNumber,
      type: 'text',
    },
    {
      id: 'nameMatch',
      label: 'Name Match',
      value: firstName ? firstName + ' ' + lastName : dashForUnvailableData,
      type: 'text',
      kycMatch: kycExtractionMatch,
    },
  ];

  const businessChamaFinancialDetails = [
    {
      id: 'taxPayerNumber',
      label: 'Tax Payer Number',

      value: investorsData?.taxPayerNumber
        ? investorsData?.taxPayerNumber
        : dashForUnvailableData,
      type: 'text',
    },
    {
      id: 'registrationNumber',
      label: 'Registration Number',
      value: investorsData?.businessChamaRegistrationNumber
        ? investorsData?.businessChamaRegistrationNumber
        : dashForUnvailableData,
      type: 'text',
    },
  ];
  const otherFields = [
    {
      id: 'dateRegistered',
      label: 'Date Registered',
      value: createdAt
        ? moment(createdAt).format('YYYY-MM-DD')
        : dashForUnvailableData,
      type: 'date',
    },
    {
      id: 'dateKYCRegistered',
      label: 'Date KYC Submitted',
      value: kycSubmittedAt
        ? moment(kycSubmittedAt).format('YYYY-MM-DD')
        : dashForUnvailableData,
      type: 'date',
    },
    {
      id: 'kycRejectedReason',
      label: 'KYC Rejected Reason',
      value: rejectionReason
        ? rejectionReason?.description
        : dashForUnvailableData,
      type: 'text',
    },
  ];

  const nextOfKinDetails = [
    {
      id: 'nextOfKinName',
      label: 'Next of Kin Name',
      value: nextOfKinNames ? nextOfKinNames : '---',
      type: 'text',
    },
    {
      id: 'nextOfKinContact',
      label: 'Next of Kin contact',
      value: nextOfKinContact ? nextOfKinContact : '---',
      type: 'text',
    },
    {
      id: 'nextOfKinEmail',
      label: 'Next of Kin email',
      value: nextOfKinEmail ? nextOfKinEmail : '---',
      type: 'text',
    },
  ];

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }

  return (
    <>
      {errorMessage ? (
        <ErrorCard backUrl={'/investors'} errorMessage={errorMessage} />
      ) : (
        <>
          <Link
            to={
              location.state?.prevUrl ? location.state?.prevUrl : '/investors'
            }
          >
            <img src={BackIcon} alt={'Back icon'} />
            <span className="ml-2">Back</span>
          </Link>
          <div className="card card-custom card-stretch gutter-b mt-2">
            <div className="card-header pt-7">
              <h4>Investor ID : {id}</h4>
            </div>

            <form
              className="form-button-headers"
              style={{
                marginBottom: '10px',
              }}
            >
              <div className="form-button-content">
                <CommonButton
                  btnText="View Transactions"
                  className="text-center full-width-small btn-size common-btn"
                  onClick={() =>
                    history.push({
                      pathname: `/investor-transactions/${id}`,
                      state: { prevUrl: location.pathname },
                    })
                  }
                />
              </div>
              <div className="form-button-content">
                <CommonButton
                  btnText="View Portfolio"
                  className="text-center full-width-small btn-size common-btn"
                  onClick={() =>
                    history.push({
                      pathname: `/investor-portfolio/${id}`,
                      state: {
                        prevUrl: location.pathname,
                      },
                    })
                  }
                />
              </div>
              <div className="form-button-content">
                <CommonButton
                  btnText="View Pocket Balance"
                  className="text-center full-width-small btn-size common-btn"
                  onClick={() =>
                    history.push({
                      pathname: `/investor-pocket-balance/${id}`,
                      state: {
                        prevUrl: location.pathname,
                      },
                    })
                  }
                />
              </div>
            </form>
            <form
              className="form-button-headers"
              style={{
                marginBottom: '10px',
              }}
            >
              <div className="form-button-content">
                <CommonButton
                  btnText="View settlement details"
                  className="text-center full-width-small btn-size common-btn"
                  onClick={() =>
                    history.push({
                      pathname: `/settlement-details/`,
                      state: { investorId: investorId },
                    })
                  }
                />
              </div>
              <div className="form-button-content">
                <CommonButton
                  btnText="View History"
                  className="text-center full-width-small btn-size common-btn"
                  onClick={() =>
                    history.push({
                      pathname: `/investor-audit-history/${id}`,
                      state: {
                        prevUrl: location.pathname,
                      },
                    })
                  }
                />
              </div>

              <div className="form-button-content">
                {grantPermission(AdminPermissions.TRANSACTION_VIEW) && (
                  <CommonButton
                    btnText="Download statement"
                    className="text-center full-width-small btn-size common-btn"
                    onClick={openStatementModal}
                    loading={pdfLoading}
                  />
                )}
              </div>
            </form>

            <div className="card-body mb-20">
              <main
                className="flex-row"
                style={{
                  justifyContent: 'space-between',
                }}
              >
                <section className="detail-section">
                  {investorType === InvestorTypes.INDIVIDUAL ? (
                    <div>
                      <FormDisplay
                        items={InvestorDetails}
                        name="Investor Details"
                      />

                      <FormDisplay
                        items={
                          investorType === InvestorTypes.INDIVIDUAL
                            ? individualFinancialDetails
                            : businessChamaFinancialDetails
                        }
                        name="Additional details"
                      />
                    </div>
                  ) : (
                    <div>
                      <FormDisplay
                        items={EntityDetails}
                        name="Entity Details"
                      />
                      <FormDisplay
                        items={directorDetails}
                        name="Director Details"
                      />
                    </div>
                  )}

                  <FormDisplay
                    items={userInvestorDetails}
                    name="Investor Address"
                  />

                  {investorsData?.users && (
                    <UsersTable users={investorsData?.users} />
                  )}

                  <FormDisplay items={otherFields} name="Other Fields" />

                  {investorType === InvestorTypes.INDIVIDUAL && (
                    <FormDisplay
                      items={nextOfKinDetails}
                      name={'Next of Kin details'}
                    />
                  )}

                  <section
                    style={{
                      marginTop: '2rem',
                      marginBottom: '2rem',
                    }}
                  >
                    <HighlightedHeader name="KYC documents" />

                    {investorType === InvestorTypes.INDIVIDUAL && (
                      <ImageContainer
                        images={individualImages}
                        handleOpeningFile={handleOpeningFile}
                        handleCardUpload={handleCardUpload}
                        downloadingImage={downloadingImage}
                        investorsData={investorsData}
                        files={files}
                        style={true}
                      />
                    )}

                    {investorType === InvestorTypes.CHAMA && (
                      <ImageContainer
                        images={otherChamaImages}
                        handleOpeningFile={handleOpeningFile}
                        handleCardUpload={handleCardUpload}
                        downloadingImage={downloadingImage}
                        investorsData={investorsData}
                        files={files}
                        style={true}
                      />
                    )}

                    {investorType === InvestorTypes.BUSINESS &&
                      investorsData?.country !== 'KE' && (
                        <ImageContainer
                          images={otherIncorpImages}
                          handleOpeningFile={handleOpeningFile}
                          handleCardUpload={handleCardUpload}
                          downloadingImage={downloadingImage}
                          investorsData={investorsData}
                          files={files}
                          style={true}
                        />
                      )}

                    {investorType === InvestorTypes.BUSINESS &&
                      investorsData.country === 'KE' && (
                        <section>
                          {investorsData?.businessType ===
                          BusinessTypes.SOLE_PROPRIETORSHIP ? (
                            <ImageContainer
                              images={otherIncorpImages}
                              handleOpeningFile={handleOpeningFile}
                              handleCardUpload={handleCardUpload}
                              downloadingImage={downloadingImage}
                              investorsData={investorsData}
                              files={files}
                              style={true}
                            />
                          ) : (
                            <ImageContainer
                              images={partnershipImages}
                              handleOpeningFile={handleOpeningFile}
                              handleCardUpload={handleCardUpload}
                              downloadingImage={downloadingImage}
                              investorsData={investorsData}
                              files={files}
                              style={true}
                            />
                          )}
                        </section>
                      )}
                  </section>

                  <HighlightedHeader name="Admin operations" />
                  <form
                    className="d-flex flex-wrap form-select-box"
                    style={{
                      width: '60%',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    <div className="sectional-select-box">
                      <label>KYC status </label>
                      <SelectBox
                        options={kycStatusValues}
                        defaultValue={investorsData.kycStatus}
                        setValue={e => setKycStatusValue(e)}
                      />
                    </div>
                    <div className="sectional-select-box">
                      <label>Investor Level </label>
                      <SelectBox
                        options={UserLevelOptions1}
                        defaultValue={investorsData.userLevel}
                        setValue={e => setUserLevel(e)}
                      />
                    </div>
                    <div className="sectional-select-box">
                      <label>Investor Plan subscription </label>

                      <input
                        type="text"
                        disabled
                        value={investorsData?.subscription?.name}
                        id={id}
                        style={{
                          width: '190px',
                          height: '45.46662521362305px',
                          borderRadius: '3.7888855934143066px',
                          background: '#F5F9FD',
                          textAlign: 'center',
                          border: '0.38px solid #CAE2F6',
                          paddingRight: '30px',
                        }}
                      />
                    </div>
                    <div className="sectional-select-box">
                      <label>Investor KYC Tier </label>
                      {investorType === InvestorTypes.INDIVIDUAL ? (
                        <SelectBox
                          options={userKycTiers}
                          defaultValue={
                            investorsData?.kycLevel?.level === 1
                              ? 'Tier One'
                              : investorsData?.kycLevel?.level === 2
                              ? 'Tier Two'
                              : 'Tier Three'
                          }
                          disabled={true}
                        />
                      ) : (
                        <input
                          type="text"
                          disabled
                          value={''}
                          id={id}
                          style={{
                            width: '190px',
                            height: '45.46662521362305px',
                            borderRadius: '3.7888855934143066px',
                            background: '#F5F9FD',
                            textAlign: 'center',
                            border: '0.38px solid #CAE2F6',
                            paddingRight: '30px',
                          }}
                        />
                      )}
                    </div>

                    {showRejectedReasons && (
                      <div className="sectional-select-box">
                        <label>KYC rejection reason </label>
                        <SelectBox
                          options={allKycRejectionReasons}
                          defaultValue={investorsData?.kycRejectionReason?.id}
                          setValue={e => setKycRejectionReasonId(e)}
                          name="Select reason"
                        />
                      </div>
                    )}
                  </form>
                </section>
              </main>

              {grantPermission(AdminPermissions.USER_STATUS_CHANGE) ? (
                <div className="mt-15 font-weight-bold text-center">
                  <button
                    className="btn  text-white  font-weight-bold px-15 py-4"
                    onClick={updateInvestorData}
                    style={{ background: '#0071CE' }}
                  >
                    Save
                    {loadingData && (
                      <span className="ml-3 spinner spinner-white" />
                    )}
                  </button>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          {openStatementModal && (
            <StatementDateModal
              open={statementModalOpen}
              setOpen={setStatementModalOpen}
              handleDateChange={handleDateChange}
              handleClose={handleStatementModalClose}
              dateValues={dateValues}
              id={id}
              setAlert={setAlert}
              handleAlert={handleAlert}
              setPdfLoading={setPdfLoading}
            />
          )}
        </>
      )}
    </>
  );
};

export default InvestorProfile;
