import * as Yup from 'yup';
import * as auth from '../_redux/authRedux';

import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { login } from '../_redux/authCrud';
import { useFormik } from 'formik';

const initialValues = {
  email: '',
  password: '',
};

const Login = props => {
  const { intl } = props;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    localStorage.removeItem('ndovuAdminToken');
    localStorage.removeItem('profile');
  }, []);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(30, 'Maximum 30 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoading();
      try {
        const res = await login(values.email, values.password);
        disableLoading();
        props.login(res.data);
      } catch (error) {
        disableLoading();
        setSubmitting(false);
        setStatus(
          error.response.data
            ? error.response.data.message
            : 'Unable to login.Try again.',
        );
      }
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-5 mb-lg-10">
        <h3 className="font-size-h1">NDOVU ADMIN PORTAL</h3>
        <p className="text-muted font-weight-bold">
          Enter your username and password
        </p>
      </div>

      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          ''
        )}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'email',
            )}`}
            name="email"
            {...formik.getFieldProps('email')}
            autoComplete="off"
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'password',
            )}`}
            name="password"
            {...formik.getFieldProps('password')}
            autoComplete="off"
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <div />
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Sign In</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
};

export default injectIntl(connect(null, { ...auth.actions })(Login));
