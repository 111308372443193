import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import ErrorCard from '../../../common/ErrorCard';

import {
  dashForUnvailableData,
  userStatusValues,
} from '../../../helpers/constants';
import BackIcon from '../../../icons/backIcon.svg';
import { fetchUser, updateUserData } from '../_redux/KycCrud';

import SelectBox from '../../../common/SelectBox';
import HighlightedHeader from '../../../common/FormDisplay/HighlightedHeader';
import DisabledInput from '../../../common/DisabledInput';

const SingleUser = props => {
  const location = useLocation();
  const { investorId } = props.match.params;
  const [loading, setLoading] = useState(true);

  const [usersData, setUserData] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const {
    status,
    id,
    email,
    firstName,
    lastName,
    acquisitionChannel,
  } = usersData;
  const [userStatus, setUserStatus] = useState(usersData?.status);
  const getUser = async () => {
    try {
      setLoading(true);
      const res = await fetchUser(investorId);
      if (res.status === 200) {
        const userData = res.data.data;
        setUserData(userData);
        setLoading(false);
        setUserStatus(userData?.status);
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }

  const onUpdateUserData = async () => {
    const formData = new FormData();

    userStatus && formData.append('userStatus', userStatus);

    try {
      setLoading(true);
      const res = await updateUserData(investorId, {
        userStatus,
      });
      if (res.status === 200) {
        getUser();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const newUserData = [
    {
      title: 'Name',
      value: firstName ? firstName + ' ' + lastName : dashForUnvailableData,

      label: 'Name',
      id: 'investorId',
      type: 'text',
    },
    {
      title: 'Email',
      value: email ? email : dashForUnvailableData,

      label: 'Email Address',
      id: 'email',
      type: 'email',
    },

    {
      title: 'User ID',
      value: id ? id : dashForUnvailableData,
      label: 'User ID',
      id: 'id',
      type: 'text',
    },

    {
      title: 'Acquisition Channel',
      value: acquisitionChannel ? acquisitionChannel : dashForUnvailableData,
      label: 'Acquisition Channel',
      id: 'acquisitionChannel',
      type: 'text',
    },
  ];

  return (
    <>
      {errorMessage ? (
        <ErrorCard backUrl={'/dashboard'} errorMessage={errorMessage} />
      ) : (
        <>
          <Link
            to={
              location.state?.prevUrl ? location.state?.prevUrl : '/dashboard'
            }
          >
            <img src={BackIcon} alt={'Back icon'} />
            <span className="ml-2">Back</span>
          </Link>
          <div className="card card-custom card-stretch gutter-b mt-2">
            <div className="card-header pt-7">
              <div>
                <h4>User Profile</h4>
                <p>Profile Detail and documentation</p>
              </div>
            </div>

            <div className="card-body mb-20">
              <HighlightedHeader name="User data" />
              <form
                className="d-flex flex-wrap "
                style={{
                  width: '553px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                {newUserData.map(item => (
                  <DisabledInput
                    key={item.id}
                    id={`${item.id}`}
                    label={`${item.label}`}
                    value={`${item.value}`}
                    type={`${item.type}`}
                    kycMatch={`${item.kycMatch ? item.kycMatch : false}`}
                  />
                ))}

                <div className="sectional-select-box">
                  <label>User status </label>
                  {userStatus && (
                    <SelectBox
                      options={userStatusValues}
                      defaultValue={
                        userStatusValues.some(
                          option => option.value === userStatus,
                        )
                          ? userStatus
                          : null
                      }
                      name="User status"
                      setValue={e => setUserStatus(e)}
                    />
                  )}
                </div>

                <div className="text-right mt-12">
                  <button
                    type="button"
                    className={`btn btn-light-primary my-auto px-9 save-btn`}
                    style={{
                      marginTop: '50px !important',
                      marginLeft: '10px',
                      padding: '0.8rem',
                      width: '200px',
                    }}
                    onClick={onUpdateUserData}
                  >
                    Save
                  </button>
                </div>
              </form>

              <section>
                <div
                  style={{
                    width: '653px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: '2rem',
                    marginBottom: '2rem',
                  }}
                >
                  <h3
                    style={{
                      color: '#0071CE',
                    }}
                  >
                    Linked Investors
                  </h3>
                  <div
                    style={{
                      background: '#0071CE',
                      height: '2px',
                      width: '653px',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                    className="separator separator-solid"
                  />
                </div>

                <div>
                  <table className="table table-responsive table-head-custom table-head-bg  table-border table-vertical-center">
                    <thead>
                      <tr
                        className="text-left text-uppercase "
                        style={{
                          fontWeight: '200',
                        }}
                      >
                        <th
                          style={{ minWidth: '70px', textAlign: 'center' }}
                          className="pl-7"
                        >
                          <span className="text-dark-75">Investor ID</span>
                        </th>
                        <th style={{ minWidth: '250px', textAlign: 'center' }}>
                          <span className="text-dark-75">Account Name</span>
                        </th>
                        <th style={{ minWidth: '180px', textAlign: 'center' }}>
                          <span className="text-dark-75">Account Type </span>
                        </th>
                        <th style={{ minWidth: '200px', textAlign: 'center' }}>
                          <span className="text-dark-75">Phone Number </span>
                        </th>
                        <th style={{ minWidth: '200px', textAlign: 'center' }}>
                          <span className="text-dark-75">Email</span>
                        </th>
                        <th style={{ minWidth: '120px', textAlign: 'center' }}>
                          <span className="text-dark-75">Role</span>
                        </th>
                        <th style={{ minWidth: '120px' }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {usersData.investors &&
                        usersData.investors.map(eachInvestor => (
                          <tr key={eachInvestor.id}>
                            <td
                              className="pl-0 py-8"
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              <div
                                style={{
                                  textAlign: 'center',
                                }}
                                className="text-dark-75  text-hover-primary mb-1 font-size-lg"
                              >
                                {eachInvestor.id}
                              </div>
                            </td>
                            <td
                              className="pl-0 py-8"
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                                {eachInvestor.investorType === 'INDIVIDUAL'
                                  ? firstName + ' ' + lastName
                                  : eachInvestor.businessChamaName}
                              </div>
                            </td>
                            <td
                              className="pl-0 py-8"
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                                {eachInvestor.investorType}
                              </div>
                            </td>
                            <td
                              className="pl-0 py-8"
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                                {eachInvestor.phoneNumber
                                  ? eachInvestor.phoneNumber
                                  : '---'}
                              </div>
                            </td>
                            <td
                              className="pl-0 py-8"
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                                {eachInvestor.investorType === 'INDIVIDUAL'
                                  ? email
                                  : eachInvestor.businessChamaEmail}
                              </div>
                            </td>
                            <td
                              className="pl-0 py-8"
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                                {eachInvestor.userInvestorRole[0]
                                  ? eachInvestor.userInvestorRole[0].role.name
                                  : '---'}
                              </div>
                            </td>
                            <td
                              className="pr-0"
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              <Link
                                to={`/investor-profile/${eachInvestor.id}`}
                                className="btn btn-light-primary  font-size-sm"
                                style={{
                                  width: '150px',
                                  padding: '1.1rem',
                                }}
                              >
                                View investor
                              </Link>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </section>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SingleUser;
