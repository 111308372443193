import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import React from 'react';
import Swal from 'sweetalert2';

import { currencyHelper, getEmail, getNames } from '../../../helpers/helper';
import {
  TransactionStatus,
  KycStatus,
  OperationType,
} from '../../../helpers/constants';
import { moveToReconciled } from '../_redux/transactionsCrud';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';
import { AdminPermissions } from '../../../helpers/constants';
import './index.scss';

function UnassignedTransaction({
  displayReasonsModal,
  createdAt,
  originalAmount,
  originalCurrency,
  id: transactionId,
  operationType,
  transactionStatus,
  transactionType,
  convertedFinalAmount,
  finalCurrency,
  conversion,
  conversionRate,
  ndovuFee,
  ndovuFeePercentage,
  appliedConversionRate,
  paymentRecord,
  planSubscription,
  handleChange,
  transactionIds,
  units,
  getTransactions,
  dateValues,
  forexNdovuFee,
  triggerSource,
}) {
  const {
    name,
    coolName,
    fundManager: { fundname },
  } = planSubscription?.plan;
  const {
    id: investorId,

    kycStatus,
    causal,
    partner: { name: partnerName },
  } = planSubscription.investor;

  const paymentChannel = paymentRecord?.paymentLinkMetadata?.paymentChannel;
  const location = useLocation();

  const handleMoveToReconciledModal = async id => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-light-success',
        cancelButton: 'btn btn-light-danger mr-1',
      },
      buttonsStyling: false,
    });
    const result = await swalWithBootstrapButtons.fire({
      title: `Are you sure about moving transaction ${id} to RECONCILED status?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, move it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      try {
        const response = await moveToReconciled(id);
        swalWithBootstrapButtons
          .fire('Created!', response.data.message, 'success')
          .then(res => {
            if (res.isConfirmed) {
              getTransactions(dateValues);
            }
          });
      } catch (error) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
          error.response.data.message,
          'error',
        );
      }
    } else {
      result.dismiss = Swal.DismissReason.cancel;
    }
  };

  const handleDropdownChange = (e, transactionId) => {
    if (e.target.value === TransactionStatus.RECONCILED) {
      handleMoveToReconciledModal(transactionId);
    } else if (e.target.value === TransactionStatus.ARCHIVED) {
      displayReasonsModal(transactionId, operationType);
    }
  };

  const displayDropdown = (
    transactionStatus,
    operationType,
    paymentChannel,
  ) => {
    if (operationType === OperationType.DEPOSIT) {
      if (paymentChannel === 'BANK_TRANSFER') {
        if (transactionStatus === TransactionStatus.TRANSFER_INCOMPLETE) {
          return (
            <select
              className="btn btn-light-primary font-weight-bold mb-5"
              style={{ paddingTop: '10px', paddingBottom: '10px' }}
              onChange={e => handleDropdownChange(e, transactionId)}
              value={transactionStatus}
            >
              <option value={TransactionStatus.TRANSFER_INCOMPLETE}>
                {TransactionStatus.TRANSFER_INCOMPLETE}
              </option>
              <option value={TransactionStatus.RECONCILED}>
                {TransactionStatus.RECONCILED}
              </option>
              <option value={TransactionStatus.ARCHIVED}>ARCHIVE</option>
            </select>
          );
        } else if (transactionStatus === TransactionStatus.RECONCILED) {
          return (
            <select
              className="btn btn-light-primary font-weight-bold mb-5"
              style={{
                paddingTop: '10px',
                paddingBottom: '10px',
                background: 'rgb(133 231 133)',
                color: 'white',
              }}
              onChange={e => handleDropdownChange(e, transactionId)}
              value={transactionStatus}
            >
              <option value={TransactionStatus.RECONCILED}>
                {TransactionStatus.RECONCILED}
              </option>
              <option value={TransactionStatus.ARCHIVED}>ARCHIVE</option>
            </select>
          );
        } else if (transactionStatus === TransactionStatus.ARCHIVED) {
          return <span> {transactionStatus} </span>;
        }
      } else {
        if (transactionStatus === TransactionStatus.PENDING) {
          return (
            <select
              className="btn btn-light-primary font-weight-bold mb-5"
              style={{ paddingTop: '10px', paddingBottom: '10px' }}
              onChange={e => handleDropdownChange(e, transactionId)}
              value={transactionStatus}
            >
              <option value={TransactionStatus.PENDING}>
                {TransactionStatus.PENDING}
              </option>
              <option value={TransactionStatus.RECONCILED}>
                {TransactionStatus.RECONCILED}
              </option>
              <option value={TransactionStatus.ARCHIVED}>
                {TransactionStatus.ARCHIVED}
              </option>
            </select>
          );
        } else if (transactionStatus === TransactionStatus.RECONCILED) {
          return (
            <select
              className="btn btn-light-primary font-weight-bold mb-5"
              style={{
                paddingTop: '10px',
                paddingBottom: '10px',
                background: 'rgb(133 231 133)',
                color: 'white',
              }}
              onChange={e => handleDropdownChange(e, transactionId)}
              value={transactionStatus}
            >
              <option value={TransactionStatus.RECONCILED}>
                {TransactionStatus.RECONCILED}
              </option>
              <option value={TransactionStatus.ARCHIVED}>
                {TransactionStatus.ARCHIVED}
              </option>
            </select>
          );
        } else if (transactionStatus === TransactionStatus.ARCHIVED) {
          return <span> {transactionStatus} </span>;
        }
      }
    } else if (operationType === OperationType.DISINVESTMENT) {
      if (transactionStatus === TransactionStatus.PENDING) {
        return (
          <select
            className="btn btn-light-primary font-weight-bold mb-5"
            style={{ paddingTop: '10px', paddingBottom: '10px' }}
            onChange={e => handleDropdownChange(e, transactionId)}
            value={transactionStatus}
          >
            <option value={TransactionStatus.PENDING}>
              {TransactionStatus.PENDING}
            </option>
            <option value={TransactionStatus.ARCHIVED}>
              {TransactionStatus.ARCHIVED}
            </option>
          </select>
        );
      } else if (transactionStatus === TransactionStatus.ARCHIVED) {
        return <span> {transactionStatus} </span>;
      }
    }
  };

  return (
    <tr
      className={
        operationType === 'DEPOSIT'
          ? 'deposit_background-color row-border-bottom'
          : 'withdraw_background-color row-border-bottom'
      }
    >
      <td className="text-center sticky-col first-col">
        <span className="text-color d-block font-size-lg">
          {getNames(planSubscription.investor)}
        </span>
      </td>

      <td className="text-center">
        <span className="text-color d-block font-size-sm">{investorId}</span>
      </td>

      <td className="text-center">
        <span className="text-color d-block font-size-sm">
          {moment(createdAt).format('YYYY-MM-DD HH:mm')}
        </span>
      </td>

      <td className="text-center">
        <span className="text-color d-block font-size-sm">
          <Link
            to={{
              pathname: `/investor-profile/${investorId}`,
              state: { prevUrl: location.pathname },
            }}
          >
            {getEmail(planSubscription.investor)}
          </Link>
        </span>
      </td>
      <td className="text-center">
        {kycStatus === 'PENDING' ? (
          <Link
            to={`/investor-profile/${investorId}`}
            className="text-color d-block font-size-sm"
          >
            {kycStatus}
          </Link>
        ) : (
          <span className="text-color d-block font-size-sm">{kycStatus}</span>
        )}
      </td>
      <td className="text-center">
        <span className="text-color d-block font-size-sm">{operationType}</span>
      </td>
      <td className="text-center">
        <span className="text-color d-block font-size-sm">{triggerSource}</span>
      </td>
      <td className="text-center">
        <span className="text-color d-block font-size-lg">
          {transactionType}
        </span>
      </td>
      <td className="text-center">
        <span className="text-color d-block font-size-sm">
          {causal ? causal : '--'}
        </span>
      </td>
      <td className="text-center">
        <span className="text-color d-block font-size-sm">{name}</span>
      </td>
      <td className="text-center">
        <span className="text-color d-block font-size-sm">{coolName}</span>
      </td>
      <td className="text-center">
        <span className="text-color d-block font-size-sm">{fundname}</span>
      </td>
      <td className="pl-0 py-8 text-center">
        <span className="text-color text-hover-primary mb-1 font-size-lg">
          {transactionId}
        </span>
      </td>
      <td className="text-center">
        <span className="text-color d-block font-size-sm">
          {paymentChannel ? paymentChannel : '--'}
        </span>
      </td>

      <td className="text-center">
        <span className="text-color d-block font-size-sm">
          {paymentRecord?.flwTransactionId
            ? paymentRecord?.flwTransactionId
            : '---'}
        </span>
      </td>

      <td className="text-center">
        <span className="text-color d-block font-size-sm">
          {currencyHelper(originalAmount, originalCurrency)}
        </span>
      </td>

      <td className="text-center">
        <span className="text-color d-block font-size-sm">
          {paymentRecord?.discountedAmount
            ? currencyHelper(
                paymentRecord.discountedAmount,
                paymentRecord.currency,
              )
            : '--'}
        </span>
      </td>

      <td className="text-center">
        <span className="text-color d-block font-size-sm">
          {Number(ndovuFee)?.toFixed(3)}
        </span>
      </td>
      <td className="text-center">
        <span className="text-color d-block font-size-sm">
          {`${Number(ndovuFeePercentage)?.toFixed(2)}%`}
        </span>
      </td>
      <td className="text-center">
        <span className="text-color d-block font-size-sm">{conversion}</span>
      </td>
      <td className="text-center">
        <span className="text-color d-block font-size-sm">
          {conversionRate ? Number(conversionRate).toFixed(3) : '---'}
        </span>
      </td>
      <td className="text-center">
        {appliedConversionRate
          ? Number(appliedConversionRate).toFixed(3)
          : '---'}
      </td>
      <td className="text-center">
        <span className="text-color d-block font-size-sm">
          {currencyHelper(convertedFinalAmount, finalCurrency)}
        </span>
      </td>

      <td className="text-center">
        {forexNdovuFee ? Number(forexNdovuFee).toFixed(3) : '---'}
      </td>
      <td className="text-center">
        {units ? Number(units).toFixed(4) : '---'}
      </td>
      <td className="text-center">
        <span className="text-color d-block font-size-sm">
          {partnerName ? partnerName : '---'}
        </span>
      </td>

      {grantPermission(AdminPermissions.TRANSACTION_STATUS_CHANGE) ? (
        <td className="text-center text-color">
          {displayDropdown(transactionStatus, operationType, paymentChannel)}
        </td>
      ) : (
        ''
      )}
      {grantPermission(AdminPermissions.BLOCK_MANIPULATION) ? (
        <td className="pr-0 text-center">
          <input
            type="checkbox"
            name="Checkboxes3_1"
            onChange={e => handleChange(e, transactionId)}
            disabled={
              kycStatus !== KycStatus.COMPLETED ||
              (transactionStatus !== TransactionStatus.RECONCILED &&
                operationType === OperationType.DEPOSIT) ||
              (transactionStatus !== TransactionStatus.PENDING &&
                operationType === OperationType.DISINVESTMENT)
            }
            checked={transactionIds.includes(transactionId)}
          />
        </td>
      ) : (
        ''
      )}
    </tr>
  );
}
export default UnassignedTransaction;
