import React from 'react';

function SelectBox({
  name,
  options,
  width = null,
  color = null,
  radius = null,
  setValue = null,
  type = null,
  defaultValue = null,
  disabled = false,
}) {
  return (
    <select
      className="btn btn-light-primary font-weight-bold"
      onChange={e =>
        setValue(
          e.target.value.toUpperCase(),
          type,
          e.target.options[e.target.selectedIndex].getAttribute('data-id'),
        )
      }
      defaultValue={defaultValue}
      disabled={disabled}
      style={{
        border: color ? `1px solid ${color}` : '1px solid #0071CE',
        color: color ? color : '#0071CE',
        background: '#FFFFFF',
        width: width != null ? width : '190px',
        height: '45px',
        borderRadius: radius ? radius : '10px',
        padding: '20px, 148px, 20px, 135px',
      }}
    >
      {name && <option value=""> {name}</option>}

      {options.map(option => (
        <option
          key={option.id}
          data-id={option.id}
          value={option.value || option.name}
        >
          {option.name || option.value}
        </option>
      ))}
    </select>
  );
}

export default SelectBox;
