import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Alert from '../../../common/Alert';
import Back from '../../../common/Back';
import Loader from '../../../common/Loader';
import { DisplaySuccessModal } from '../../../common/SelectNumberOfRows/SuccessModal';
import { incomeTypes } from '../../../helpers/constants';
import { formatNumber } from '../../../helpers/helper';
import { fetchPlanDetails, payDividends } from '../_redux/planCrud';

export const PlanDetails = () => {
  const { planId } = useParams();
  const [amountPerUnit, setAmountPerUnit] = useState();
  const [planDetails, setPlanDetails] = useState();
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [loading, setLoading] = useState(false);

  const updatePlanDetailsState = async () => {
    try {
      setLoading(true);
      const response = await fetchPlanDetails(planId);
      const planData = response?.data?.data;
      setPlanDetails(planData);
      setLoading(false);
    } catch (error) {
      setAlert({
        alertMessage: error?.response?.data?.message,
        alertMessageType: 'error',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    updatePlanDetailsState();
  }, []);

  const handleSubmit = async () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-light-success',
        cancelButton: 'btn btn-light-danger mr-1',
      },
      buttonsStyling: false,
    });

    const result = await swalWithBootstrapButtons.fire({
      title: `An amount of ${calculateAmountToBePaid(
        planDetails,
        amountPerUnit,
      )} is going to be disbursed to the platform users, Are you sure you want to proceed?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Continue',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      setAlert({
        alertMessage: null,
        alertMessageType: null,
      });

      const payload = {
        planId: Number(planId),
      };

      if (planDetails?.incomeType === incomeTypes.DIVIDENDS) {
        payload.amountPerUnit = Number(amountPerUnit);
      } else {
        payload.percentageInterestRate = Number(amountPerUnit);
      }

      try {
        setLoading(true);
        const response = await payDividends(payload);

        DisplaySuccessModal(response?.data?.message);

        setLoading(false);
      } catch (error) {
        setAlert({
          alertMessage: error?.response?.data?.message,
          alertMessageType: 'error',
        });
        setLoading(false);
      }

      setAmountPerUnit();
    } else {
      result.dismiss = Swal.DismissReason.cancel;
    }
  };

  const calculateAmountToBePaid = (planDetails, amountPerUnit) => {
    const { incomeType, totalUnitsHeld } = planDetails;

    const amount =
      incomeType !== incomeTypes.DIVIDENDS
        ? totalUnitsHeld * (amountPerUnit / 100)
        : totalUnitsHeld * amountPerUnit;

    return formatNumber(amount.toFixed(4));
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="mb-5">
            <Back url={`/plans`} />
          </div>

          <div className="card card-custom gutter-b">
            <div className="card-header">
              <h4 className="pt-8">Fund Details</h4>
            </div>

            <div className="card-body">
              <div className="mb-5">
                {alert?.alertMessage ? (
                  <Alert alert={alert} alignText={'text-left'} />
                ) : null}
              </div>

              <div>
                <div>
                  Fund:{' '}
                  <span className="font-weight-bold">
                    {planDetails?.coolName}
                  </span>
                </div>

                <div>
                  Symbol:{' '}
                  <span className="font-weight-bold">
                    {planDetails?.identifier}
                  </span>
                </div>

                <div>
                  Total Amount of units held:{' '}
                  <span className="font-weight-bold">
                    {planDetails?.totalUnitsHeld &&
                      formatNumber(planDetails?.totalUnitsHeld.toFixed(4))}
                  </span>
                </div>

                <div>
                  Dividend percentage fee:{' '}
                  <span className="font-weight-bold">
                    {planDetails?.dividendPercentageFee
                      ? planDetails?.dividendPercentageFee
                      : '--'}
                  </span>
                </div>

                <div>
                  Total investors holding the fund:{' '}
                  <span className="font-weight-bold">
                    {planDetails?.totalInvestors}
                  </span>
                </div>
              </div>

              <div
                style={{ gap: '45px' }}
                className="d-flex align-items-center mt-10"
              >
                <div
                  style={{
                    maxWidth: '180px',
                  }}
                  className="form-group mb-2"
                >
                  <div>
                    <input
                      className="form-control"
                      type="number"
                      placeholder={
                        planDetails?.incomeType !== incomeTypes.DIVIDENDS
                          ? 'Percentage interest'
                          : 'Amount per unit'
                      }
                      name="amountPerUnit"
                      min={0}
                      autoComplete="off"
                      value={amountPerUnit || ''}
                      onChange={e => setAmountPerUnit(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  {planDetails?.incomeType !== incomeTypes.DIVIDENDS
                    ? '%'
                    : planDetails?.currency}
                </div>

                <div>
                  <button
                    className="btn btn-light-primary font-weight-bold"
                    onClick={handleSubmit}
                    disabled={!amountPerUnit || !planDetails.incomeType}
                  >
                    Pay
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
