/* eslint-disable jsx-a11y/anchor-is-valid */

import '../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss';

import { Link, Redirect, Switch } from 'react-router-dom';

import { ContentRoute } from '../../../../_metronic/layout';
import Login from './Login';
import React from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { useHistory } from 'react-router-dom';

export function AuthPage() {
  const history = useHistory();
  history.listen((location, action) => {});

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={{
              backgroundImage: `url(${toAbsoluteUrl('/media/bg/bg-4.jpg')})`,
            }}
          >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              {/* start:: Aside header */}
              <Link to="/" className="flex-column-auto mt-5">
                <img
                  alt="Ndovu Logo"
                  className="max-h-35px"
                  src={toAbsoluteUrl('/media/logos/resized_logo.png')}
                />
              </Link>
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div className="flex-column-fluid d-flex flex-column justify-content-center">
                {/* <h4 className='font-size-h4 mb-5 text-white'>Ndovu</h4> */}
                <p className="font-weight-lighter text-white opacity-80">
                  Democratizing Investment
                </p>
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold	text-white">
                  &copy; {new Date().getFullYear()} Ndovu
                </div>
                <div className="opacity-70 font-weight-bold	text-white">
                  {/* <Link style={{ color: "white" }} to='/wizard'>
                    Wizard
                  </Link> */}
                </div>
                {/* Removed Links here */}
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            {/*end::Content body*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
