import React from 'react';
import DisabledInput from '../DisabledInput';
import HighlightedHeader from './HighlightedHeader';
function FormDisplay({ items, name }) {
  return (
    <>
      <HighlightedHeader name={name} />
      <form
        className="d-flex flex-wrap "
        style={{
          width: '75%',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        {items.map(item => (
          <DisabledInput
            key={item.id}
            id={`${item.id}`}
            label={`${item.label}`}
            value={`${item.value}`}
            type={`${item.type}`}
            kycMatch={`${item.kycMatch ? item.kycMatch : false}`}
          />
        ))}
      </form>
    </>
  );
}

export default FormDisplay;
